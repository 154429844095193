
import { Component, Vue } from 'vue-property-decorator'
import { DicInfo } from '@/types/common'
import { ElForm } from 'element-ui/types/form'

@Component
export default class Dictionary extends Vue {
  private libTypeList: Array<DicInfo> = []
  private libType = ''

  private dialogFormVisible = false
  private title = '新增数据项'

  private fieldKey = ''
  private info = {
    fieldName: ''
  }

  private rules = {
    fieldName: [{ required: true, message: '请输入字典名称', trigger: ['blur'] }]
  }

  private submitShow = false

  private tableData = {
    loading: false,
    tr: [
      {
        label: '字典编码',
        prop: 'fieldKey',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '字典名称',
        prop: 'fieldName',
        minWidth: '120',
        showOverflowTooltip: true
      }
    ],
    data: [{}]
  }

  created () {
    this.getDicList()
  }

  // 字段列表
  getDicList () {
    this.$axios.get(this.$apis.businessData.selectDicByPage, {
      dicType: 'libType'
    }).then((res) => {
      this.libTypeList = res.list || []
      if (this.libTypeList.length) {
        this.searchData(this.libTypeList[0])
      }
    })
  }

  // 数据项列表
  getList () {
    this.$axios.get(this.$apis.preBidCalc.selectCalculateLibraryFieldByList, {
      libType: this.libType
    }).then((res) => {
      this.tableData.data = res.list || []
    })
  }

  searchData (item: DicInfo) {
    this.libType = item.dicCode
    this.getList()
  }

  onAdd () {
    this.title = '新增数据项'
    this.dialogFormVisible = true
    this.info.fieldName = ''
  }

  onUpdate (data: { fieldKey: string; fieldName: string }) {
    this.title = '编辑数据项'
    this.dialogFormVisible = true
    this.fieldKey = data.fieldKey
    this.info.fieldName = data.fieldName
  }

  onCancel () {
    this.fieldKey = ''
    this.dialogFormVisible = false
  }

  onDelete (fieldKey: string) {
    this.$confirm('是否确认删除该数据项？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.preBidCalc.deleteDataCalculateLibraryField, {
        fieldKey
      }).then(() => {
        this.$message.success('操作成功')
        this.getList()
      })
    })
  }

  submitForm () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        let info: any = {
          libType: this.libType,
          ...this.info
        }
        let url = this.$apis.preBidCalc.insertCalculateLibraryField
        if (this.fieldKey) {
          info = {
            ...info,
            fieldKey: this.fieldKey
          }
          url = this.$apis.preBidCalc.updateCalculateLibraryField
        }
        this.$axios.post(url, info).then(() => {
          this.$message.success('保存成功')
          this.onCancel()
          this.getList()
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
